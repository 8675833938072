import { css, Global, styled, Text, useTranslation } from '@obvio/app'
import { useUi } from '@obvio/template'
import { Button, Stack } from '@obvio/ui'
import { useEffect } from 'react'

import { Layout } from '@/components/Layout'
import { HeroSection } from '@/components/Renderer/RendererVariants/HeroSection'

import type { ReactElement } from 'react'

const globalStyle = css`
  body {
    color: #fff !important;
    background: #000 !important;
  }
  .next-error-h1 {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
`

const Wrap = styled.div`
  z-index: 49;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  * {
    color: white !important;
  }
`

function Page({ statusCode: _s }: { statusCode: any }): ReactElement {
  const { t } = useTranslation()
  const [, dispatch] = useUi()

  useEffect(() => {
    dispatch('CLOSE_ALL_PANELS')
  }, [dispatch])

  return (
    <>
      <Layout type="Główny" hideCalendar>
        <HeroSection type="image" hideForm>
          <Wrap>
            <Stack kind="vertical">
              <Text tag="h1">{t('label.notFound')}</Text>
              <Button href="/" kind="secondary">
                {t('label.goToLanding')}
              </Button>
            </Stack>
          </Wrap>
        </HeroSection>
      </Layout>
      <Global styles={globalStyle} />
    </>
  )
}

// Page.getInitialProps = ({ res, err }: { res: any; err: any }) => {
//   const statusCode = res ? res.statusCode : err ? err.statusCode : 404
//   return { statusCode }
// }

export default Page
